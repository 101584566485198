export interface IPartern {
    title: string;
    link?: string;
    imageSrc?: string
}

export const PartnerList: Array<IPartern> = [
    {
        title: "ABI",
        imageSrc: "/images/Partners/abi_logo.png"
    },
    {
        title: "MTN",
        imageSrc: "/images/Partners/New-mtn-logo.jpg",
        link: "h"
    }
]