import { PartnerList } from "./PartnerList"

export const Partners = () => {
    return (
        <section id="partners" className="container-fluid d-flex justify-content-center bg-black">
            <div className="row align-items-center text-white partners align-content-center justify-content-center pb-5">
                <div className="col-lg-12" >
                    <center><h4 className="my-5">Our Partners</h4></center>
                </div>
                <div className="d-flex col-lg-12 flex-wrap justify-content-center gap-5">
                    {PartnerList.map((partner, i) => (
                        <span key={i} className="d-flex align-items-center">
                            <img
                                src={partner.imageSrc}
                                alt=""
                                style={{ width: "auto", height: 120, }}
                            />
                        </span>
                    ))}
                </div>
            </div>
        </section>
    )
}